import { Layout, LayoutHeader, LayoutMain } from '@sitecore-ui/design-system'
import TopNavigation from '../../TopNavigation/components/TopNavigation'
import { Route, Routes } from 'react-router-dom'
import NoMatch from '../../App/Routing/NoMatch'

const Main: React.FC = () => {
  return (
    <Layout>
      <LayoutHeader>
        <TopNavigation />
      </LayoutHeader>
      <LayoutMain>
        <Routes>
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </LayoutMain>
    </Layout>
  )
}

export default Main
