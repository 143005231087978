import { NavigationHorizontal } from '@sitecore-ui/design-system'
import logo from '../../common/assets/images/oc_logo.svg'
import { useNavigate } from 'react-router-dom'
import { mockUser } from '../../mocks/mockUser'

const TopNavigation = (props: any) => {
  let navigate = useNavigate()
  return (
    <>
      <NavigationHorizontal
        items={[
          {
            label: 'Dashboard',
            onClick: () => navigate('/'),
          },
          {
            label: 'Admin Users',
            onClick: () => navigate('/adminUsers'),
          },
        ]}
        menu={{
          items: [
            {
              label: 'My Profile',
              onClick: () => navigate('/profile'),
            },
            {
              label: 'Logout',
              onClick: () => console.log('logging out!'),
            },
          ],
          name: `${mockUser.givenName} ${mockUser.familyName}`,
        }}
        image={{
          w: '150px',
          src: logo,
        }}
      />
    </>
  )
}

export default TopNavigation
