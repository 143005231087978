import { SitecoreUIProvider } from '@sitecore-ui/design-system'
import React from 'react'
import Main from './Main/components/Main'
import { BrowserRouter } from 'react-router-dom'

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <SitecoreUIProvider>
        <Main />
      </SitecoreUIProvider>
    </BrowserRouter>
  )
}

export default App
