export const mockUser = {
  id: 'auth0|507f1f77bcf86cd799439020',
  email: 'john.doe@acme.com',
  emailVerified: false,
  created: '2021-08-04T16:17:01.542Z',
  updated: '2021-08-04T16:17:01.542Z',
  lastLogin: '2021-08-04T16:17:01.542Z',
  signUpCompleted: true,
  givenName: 'John',
  familyName: 'Doe',
  nickName: 'jd',
  phoneNumber: '+18889876543',
  company: 'Acme Corporation',
  jobRole: 'Developer',
  preferredLanguage: 'en-US',
  region: 'East US',
}
