import { H1 } from '@sitecore-ui/design-system';

const NoMatch: React.FC = () => {

    return (
        <>
            <H1>Page not found.</H1>
        </>
    );
};

export default NoMatch;